import React from "react"; // Add this line
import { HomeDemo1Faq } from "../../utils/allImgs";
import SectionHeading from "../SectionHeading";

const Faq = ({ data, ClassSpanTitle }) => {
  const answers = {
    Text1: "Goat Token aims to revolutionize digital assets by offering secure, scalable, and decentralized solutions. Its objectives include empowering users with fast transactions, decentralized governance, and staking opportunities.",
    Text2: "Goat Token delivers features like fast and low-cost transactions, staking rewards, decentralized governance, and integration with Web3 social platforms, making it a versatile ecosystem for users.",
    Text3: "This ICO is your gateway to becoming part of an innovative ecosystem that bridges decentralized finance with everyday use. Early participation offers potential for growth and rewards.",
    Text4: "You can participate in the ICO by visiting our official platform, signing up, and purchasing tokens using supported cryptocurrencies or fiat options.",
  };

  return (
    <div className="faq-timeline-area section-padding-0-85 mt-5" id="faq">
      <div className="container">
        <SectionHeading
          title="Token FAQ"
          text="Frequently Questions"
          ClassSpanTitle={ClassSpanTitle}
        />
        <div className="row align-items-center">
          <div className="col-12 col-lg-6 offset-lg-0 col-md-8 offset-md-2 col-sm-12">
            <img
              draggable="false"
              src={HomeDemo1Faq}
              alt=""
              className="center-block img-responsive"
            />
          </div>
          <div className="col-12 col-lg-6 col-md-12">
            <div className="dream-faq-area mt-s ">
              <dl id="basicAccordion">
                {data &&
                  data.map((item, key) => (
                    <React.Fragment key={key}>
                      <dt
                        className="wave"
                        data-bs-toggle="collapse"
                        data-bs-target={`#${item.ID}`}
                        aria-expanded="false"
                      >
                        {item.text}
                      </dt>
                      <dd
                        data-aos="fade-up"
                        id={item.ID}
                        aria-labelledby="headingOne"
                        data-bs-parent="#basicAccordion"
                        className="accordion-collapse collapse"
                      >
                        <p className="accordion-body">{answers[item.ID]}</p>
                      </dd>
                    </React.Fragment>
                  ))}
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
