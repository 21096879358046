const SecAbout = ({imgDwon=false , title , text , img}) => {

  return (
    <>
      {imgDwon ? (
          <section className="about-us-area section-padding-100 clearfix">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-12 col-lg-6 offset-lg-0">
                  <div className="who-we-contant mt-s">
                    <div className="dream-dots text-left" data-aos="fade-up" data-aos-delay="300">
                      <span className="gradient-text blue">{title}</span>
                    </div>
                    <h4 data-aos="fade-up" data-aos-delay="300">{text}</h4>
                    <p data-aos="fade-up" data-aos-delay="300">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis at dictum risus, non suscipit arcu. Quisque aliquam posuere tortor, sit amet convallis nunc scelerisque in.</p>
                    <p data-aos="fade-up" data-aos-delay="400">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Suscipit ipsa ut quasi adipisci voluptates, voluptatibus aliquid alias beatae reprehenderit incidunt iusto laboriosam.</p>
                    <a className="btn more-btn mt-30" href="#">Read More</a>
                  </div>
                </div>
                <div className="col-12 col-lg-6 offset-lg-0 col-md-12 no-padding-left">
                  <div className="welcome-meter" data-aos="fade-up" data-aos-delay="200">
                    <img draggable="false" src={img} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (

          <section className="about-us-area section-padding-100 clearfix">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-12 col-lg-6 offset-lg-0 col-md-12 no-padding-left">
                  <div className="welcome-meter" data-aos="fade-up" data-aos-delay="200">
                    <img draggable="false" src={img} alt="" />
                  </div>
                </div>
                <div className="col-12 col-lg-6 offset-lg-0">
                  <div className="who-we-contant mt-s">
                    <div className="dream-dots text-left" data-aos="fade-up" data-aos-delay="300">
                      <span className="gradient-text ">Decentralized Trading Platform</span>
                      </div>
              <h4 className="fadeInUp" data-aos="fade-up" data-aos-delay="200">The GT Protocol</h4>
              <p className="fadeInUp" data-aos="fade-up" data-aos-delay="200">The GT protocol is a token that works using the GT protocol. It is a decentralized platform that enables secure and transparent transactions. The GT protocol is designed to be fast, scalable, and secure, making it an ideal platform for a wide range of applications.</p>
              <p className="fadeInUp" data-aos="fade-up" data-aos-delay="300">The GT protocol is built on top of the Ethereum blockchain, which means that it is compatible with all Ethereum-based applications. This makes it easy for developers to build decentralized applications (DApps) on top of the GT protocol.</p>
              <p className="fadeInUp" data-aos="fade-up" data-aos-delay="300">The GT protocol also has its own native token, GT. The GT token is used to pay for transaction fees on the GT network. It can also be used as a store of value and for other purposes within the GT ecosystem.</p>
            </div>
                </div>
              </div>
            </div>
          </section>

        )}
    </>
  );
}

export default SecAbout;