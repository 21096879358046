import HomeDemo3Container from '../containers/HomeDemo3'

const HomeDemo3 = () => {

  return (
    <>
      <HomeDemo3Container />
    </>
  );
}

export default HomeDemo3;

