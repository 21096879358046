import {

    HomeDemo2IconsH1,
    HomeDemo2IconsH11,
    HomeDemo2IconsH2,
    HomeDemo2IconsH22,
    HomeDemo2IconsH3,
    HomeDemo2IconsH33,

    HomeDemo2FeaturesS1,
    HomeDemo2FeaturesS2,
    HomeDemo2FeaturesS3,
    HomeDemo2FeaturesS4,

    HomeDemo2FeaturesF1,
    HomeDemo2FeaturesF2,
    HomeDemo2FeaturesF3,
    HomeDemo2FeaturesF4,
    HomeDemo2FeaturesF5,
    HomeDemo2FeaturesF6,

    HomeDemo2TeamImg1,
    HomeDemo2TeamImg2,
    HomeDemo2TeamImg3,
    HomeDemo2TeamImg4,

    HomeDemo2BlogImg1,
    HomeDemo2BlogImg2,
    HomeDemo2BlogImg3,

} from '../../../utils/allImgs'

export const HowItWorksInfo = [
    {
        img1:HomeDemo2IconsH1,
        img2:HomeDemo2IconsH11,
        steps:1,
        title:"Register New Account"
    },
    {
        img1:HomeDemo2IconsH2,
        img2:HomeDemo2IconsH22,
        steps:2,
        title:"Setup Account Info"
    },
    {
        img1:HomeDemo2IconsH3,
        img2:HomeDemo2IconsH33,
        steps:3,
        title:"Start Buying &amp; Selling"
    }
]

export const SmartContractinfo = [
    {
        img:HomeDemo2FeaturesS1,
        title:"Cycles and schedules"

    },
    {
        img:HomeDemo2FeaturesS2,
        title:"Advanced application interface (API)"

    },
    {
        img:HomeDemo2FeaturesS3,
        title:"Software development kit (SDK)"

    },
    {
        img:HomeDemo2FeaturesS4,
        title:"Java virtual machine (JVM)"

    },
]

export const Features2InfoTop = [
    {
        img:HomeDemo2FeaturesF1,
        title:"Delivery Reports"
    },
    {
        img:HomeDemo2FeaturesF2,
        title:"Branded Sender"
    },
    {
        img:HomeDemo2FeaturesF3,
        title:"SMS Messaging"
    }
]

export const Features2InfoDown = [
    {
        img:HomeDemo2FeaturesF4,
        title:"Marketing Plans"
    },
    {
        img:HomeDemo2FeaturesF5,
        title:"Seed Routing"
    },
    {
        img:HomeDemo2FeaturesF6,
        title:"Traking API"
    }
]

export const OurTeamInfo = [
    {
        img:HomeDemo2TeamImg1,
        title:"Joman Helal",
        text:"Executive Officer"
    },
    {
        img:HomeDemo2TeamImg2,
        title:"Slans Alons",
        text:"Business Development"
    },
    {
        img:HomeDemo2TeamImg3,
        title:"Josha Michal",
        text:"UX/UI Designer"
    },
    {
        img:HomeDemo2TeamImg4,
        title:"Johan Wright",
        text:"Head of Marketing"
    }
]

export const OurBlogInfo = [
    {
        img:HomeDemo2BlogImg1,
        title:"What is this Token for?."
    },
    {
        img:HomeDemo2BlogImg2,
        title:"The most powerful Token"
    },
    {
        img:HomeDemo2BlogImg3,
        title:"How to get trial version"
    }
]