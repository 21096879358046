import {
  HomeDemo1Computer
} from '../../utils/allImgs'

const AboutOther = ({ClassTitle="gradient-text blue"}) => {

  return (

    <section className="about-us-area section-padding-0-100 clearfix">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-lg-6 offset-lg-0">
            <div className="who-we-contant">
              <div className="dream-dots text-left fadeInUp" data-aos="fade-up" data-aos-delay="200">
                <span className={ClassTitle}>Overview of Goat Token</span>
              </div>
              <div>
    <h3 className="fadeInUp" data-aos="fade-up" data-aos-delay="200">Purpose</h3>
    <p className="fadeInUp" data-aos="fade-up" data-aos-delay="300">
        Goat Token is a new Polygon cryptocurrency that aims to revolutionize the digital asset space by providing a secure, decentralized, and efficient platform for users to transact and store value.
    </p>

    <h3 className="fadeInUp" data-aos="fade-up" data-aos-delay="400">Features</h3>
    <p className="fadeInUp" data-aos="fade-up" data-aos-delay="500">
        <strong>Secure and Transparent:</strong> Built on the Polygon blockchain, Goat Token utilizes advanced cryptography and smart contracts to ensure the security and transparency of transactions.
    </p>
    <p className="fadeInUp" data-aos="fade-up" data-aos-delay="600">
        <strong>Decentralized Governance:</strong> Goat Token major holders have the power to participate in the decision-making process through decentralized governance.
    </p>
    <p className="fadeInUp" data-aos="fade-up" data-aos-delay="700">
        <strong>Efficient and Scalable:</strong> With the use of the Polygon network, Goat Token offers fast and low-cost transactions, making it suitable for everyday use.
    </p>

    <h3 className="fadeInUp" data-aos="fade-up" data-aos-delay="800">Benefits</h3>
    <p className="fadeInUp" data-aos="fade-up" data-aos-delay="900">
        <strong>Accessibility:</strong> Goat Token aims to be available on most exchange platforms by 2025, making it easily accessible to a wide range of users.
    </p>
    <p className="fadeInUp" data-aos="fade-up" data-aos-delay="1000">
        <strong>Potential for Growth:</strong> As a new cryptocurrency, Goat Token has the potential for significant growth and value appreciation.
    </p>
    <p className="fadeInUp" data-aos="fade-up" data-aos-delay="1100">
        <strong>Community-driven:</strong> The Goat Token community plays a vital role in the development and success of the project, fostering a sense of ownership and engagement.
    </p>
</div>

            </div>
          </div>
          <div className="col-12 col-lg-6 offset-lg-0 col-md-12 mt-30 no-padding-left">
            <div className="welcome-meter floating-anim fadeInUp">
              <img draggable="false" src={HomeDemo1Computer} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutOther;