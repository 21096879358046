import HomeDemo4Container from '../containers/HomeDemo4'

const HomeDemo4 = () => {

  return (
    <>
      <HomeDemo4Container />
    </>
  );
}

export default HomeDemo4;

