import HomeDemo2Container from '../containers/HomeDemo2'

const HomeDemo2 = () => {

  return (
    <>
      <HomeDemo2Container />
    </>
  );
}

export default HomeDemo2;

