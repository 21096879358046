import {
  HomeDemo1Platform,
  HomeDemo1Rings,
} from "../../../utils/allImgs";

import SectionHeading from "../../../components/SectionHeading";

const OurPlatform = () => {
  return (
    <section className="features section-padding-0-100">
      <div className="container">
        <SectionHeading
          title="Try our Social Media Platform"
          text="Our Social Media Platform"
        />

        <div className="row align-items-center">
          <div className="service-img-wrapper col-lg-5 col-md-12 col-sm-12 no-padding-right">
            <div className="features-list">
              <div className="who-we-contant">
                <h4 className="w-text" data-aos="fade-up" data-aos-delay="200">
                  Powerful social platform
                </h4>
                <p className="w-text" data-aos="fade-up" data-aos-delay="300">
                  We are dedicated to providing professional service with the highest degree of honesty and integrity, and strive to add value to our token with inivative DAPPS.
                </p>
              </div>
              <ul className="list-marked">
                <li className="text-white">
                  <i className="fa fa-check" />
                  <a
                    href="https://gbook.run"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: 'white', textDecoration: 'underline' }}
                  >
                    Gbook.run - Social Connections Simplified
                  </a>
                </li>
                <li className="text-white">
                  <i className="fa fa-check" />
                  <a
                    href="https://gpost.run"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: 'white', textDecoration: 'underline' }}
                  >
                    Gpost.run - Instant Microblogging
                  </a>
                </li>
                <li className="text-white">
                  <i className="fa fa-check" />
                  <a
                    href="https://gtube.run"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: 'white', textDecoration: 'underline' }}
                  >
                    Gtube.run - Endless Video Entertainment
                  </a>
                </li>
                <li className="text-white">
                  <i className="fa fa-check" />
                  <a
                    href="https://ggram.run"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: 'white', textDecoration: 'underline' }}
                  >
                    Ggram.run - Capturing Moments Creatively
                  </a>
                </li>
              </ul>

            </div>
          </div>
          <div className="service-img-wrapper col-lg-7 col-md-12 col-sm-12 mt-s">
            <div className="image-box">
              <img
                draggable="false"
                src={HomeDemo1Platform}
                className="center-block img-responsive phone-img"
                alt=""
              />
              <img
                draggable="false"
                src={HomeDemo1Rings}
                className="center-block img-responsive rings"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurPlatform;
