import HomeDemo6Container from '../containers/HomeDemo6'

const HomeDemo6 = () => {

  return (
    <>
      <HomeDemo6Container />
    </>
  );
}

export default HomeDemo6;

