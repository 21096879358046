import HomeDemo5Container from '../containers/HomeDemo5'

const HomeDemo5 = () => {

  return (
    <>
      <HomeDemo5Container />
    </>
  );
}

export default HomeDemo5;

