import Contract from "./Contract"

const SmartContract = ({data}) => {

    return (

      <section className="smart-contract section-padding-0-70 mt-5 clearfix">
        <div className="container">
          <div className="row ">
            <div className="col-12 col-lg-6 offset-lg-0">
              <div className="who-we-contant">
                <div className="dream-dots text-left fadeInUp" data-wow-delay="0.2s">
                  <span className="gradient-text blue">Smart Contract</span>
                </div>
                <h4 className="fadeInUp" data-wow-delay="0.3s">Comprehensive smart contracts.</h4>
                <p className="fadeInUp" data-wow-delay="0.4s">Goat Token leverages comprehensive smart contracts to ensure secure, transparent, and automated transactions. These contracts eliminate intermediaries, reducing costs and improving efficiency while providing users with complete control over their assets. Built on the Polygon blockchain, Goat Token’s smart contracts are designed to handle complex processes with precision and reliability.</p>
                <p className="fadeInUp" data-wow-delay="0.5s">With features like decentralized governance and staking rewards integrated into the contracts, Goat Token creates a robust ecosystem for its community. The scalability and flexibility of these smart contracts enable seamless integration with DApps, making Goat Token a versatile choice for both individual users and developers.</p>
              </div>
            </div>
            <div className="col-12 col-lg-6 offset-lg-0 mt-s">
              {data && data.map((item , key) => (
                <Contract
                  key={key}
                  img={item.img}
                  title={item.title}
                />
              ))}
            </div>
          </div>
        </div>
      </section>
    );

}

export default SmartContract;