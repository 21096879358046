const PricingItem = ({ Round, dollar, description }) => {
  return (
    <div className="col-12 col-md-6 col-lg-3">
      <div className="pricing-item">
        <h4>Phase {Round}</h4>
        <p>Price: {dollar}</p>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default PricingItem;
