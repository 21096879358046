const SecHeroSection = ({
      ClassSec="",
      ClassDiv="",
      specialHead="",
      title="",
      link1="",
      link2="",
      link3="",
      img="",
      HomeDemo1Or3Or4Or5Or6=true,
      HomeDemo1Or4Or5Or6=true,

    }) => {

      // AbsoImgInfo="",
      // AddWrapper=false,
      // HomeDemo1=false,
      // HomeDemo2=false,
      // HomeDemo3=false,
      // HomeDemo4=false,
      // HomeDemo5=false,
      // HomeDemo6=false

  return (
    <section className={ClassSec} id="home">
      <div className="hero-section-content">
        <div className="container ">
          <div className="row align-items-center">
            <div className={ClassDiv}>
              <div className="welcome-content">
                <div className="promo-section">
                  <h3 className="special-head dark">{specialHead}</h3>
                </div>
                <h1>{title}</h1>
                <p className="w-text fadeInUp" data-wow-delay="0.3s">Goat Token is a groundbreaking cryptocurrency ecosystem blending DeFi innovation with the power of community. It empowers users through staking and rewards, redefining the future of decentralized finance. 🚀✨</p>
                <div className="dream-btn-group fadeInUp" data-wow-delay="0.4s">
  <div style={{ marginBottom: "10px" }}>
    <a
      href="https://embed.ipfscdn.io/ipfs/bafybeigdie2yyiazou7grjowoevmuip6akk33nqb55vrpezqdwfssrxyfy/erc20.html?contract=0xc149435a7350Cb7e9A6488EeEB906F07F090CfeE&chain=%7B%22name%22%3A%22Ethereum+Mainnet%22%2C%22chain%22%3A%22ETH%22%2C%22rpc%22%3A%5B%22https%3A%2F%2F1.rpc.thirdweb.com%2F%24%7BTHIRDWEB_API_KEY%7D%22%5D%2C%22nativeCurrency%22%3A%7B%22name%22%3A%22Ether%22%2C%22symbol%22%3A%22ETH%22%2C%22decimals%22%3A18%7D%2C%22shortName%22%3A%22eth%22%2C%22chainId%22%3A1%2C%22testnet%22%3Afalse%2C%22slug%22%3A%22ethereum%22%2C%22icon%22%3A%7B%22url%22%3A%22ipfs%3A%2F%2FQmdwQDr6vmBtXmK2TmknkEuZNoaDqTasFdZdu3DRw8b2wt%22%2C%22width%22%3A1000%2C%22height%22%3A1628%2C%22format%22%3A%22png%22%7D%7D&clientId=1f0412548ad961de1b0f68d62bbe5ac8&theme=dark&primaryColor=blue"
      className="btn more-btn"
    >
      {link1}
    </a>
  </div>
  <div style={{ marginBottom: "10px" }}>
    <a
      href="https://embed.ipfscdn.io/ipfs/bafybeigdie2yyiazou7grjowoevmuip6akk33nqb55vrpezqdwfssrxyfy/erc20.html?contract=0x24049e208dcf577B6Ac8aa15c88e49121D52637D&chain=%7B%22name%22%3A%22BNB+Smart+Chain+Mainnet%22%2C%22chain%22%3A%22BSC%22%2C%22rpc%22%3A%5B%22https%3A%2F%2F56.rpc.thirdweb.com%2F%24%7BTHIRDWEB_API_KEY%7D%22%5D%2C%22nativeCurrency%22%3A%7B%22name%22%3A%22BNB+Chain+Native+Token%22%2C%22symbol%22%3A%22BNB%22%2C%22decimals%22%3A18%7D%2C%22shortName%22%3A%22bnb%22%2C%22chainId%22%3A56%2C%22testnet%22%3Afalse%2C%22slug%22%3A%22binance%22%2C%22icon%22%3A%7B%22url%22%3A%22ipfs%3A%2F%2FQmcxZHpyJa8T4i63xqjPYrZ6tKrt55tZJpbXcjSDKuKaf9%2Fbinance-coin%2F512.png%22%2C%22width%22%3A512%2C%22height%22%3A512%2C%22format%22%3A%22png%22%7D%7D&clientId=1f0412548ad961de1b0f68d62bbe5ac8&theme=dark&primaryColor=yellow"
      className="btn more-btn"
    >
      {link2}
    </a>
  </div>
  <div>
    <a href="https://goatstake.xyz" className="btn more-btn">
      {link3}
    </a>
  </div>
</div>

              </div>
            </div>
            {HomeDemo1Or3Or4Or5Or6 && (
              <div className="col-lg-6">
                {HomeDemo1Or4Or5Or6 && (
                  <div className="illusto-2">
                    <img draggable="false" src={img} alt="" />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>

  );
}

export default SecHeroSection;


    // <>
    //   {HomeDemo1 && (
    //     <section className={"hero-section moving section-padding"} id="home">
    //       <div className="moving-bg" />
    //       <div className="hero-section-content">
    //         <div className="container ">
    //           <div className="row align-items-center">
    //             <div className={"col-12 col-lg-5 col-md-12"}>
    //               <div className="welcome-content">
    //                 <div className="promo-section">
    //                   <h3 className="special-head dark">{specialHead}</h3>
    //                 </div>
    //                 <h1 className="" data-aos-delay="200">{title}</h1>
    //                 <p className="w-text " data-aos-delay="300">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eveniet dolorem blanditiis ad perferendis, labore delectus dolor sit amet, adipisicing elit. Eveniet.</p>
    //                 <div className="dream-btn-group " data-aos-delay="400">
    //                   <a href="#" className="btn more-btn mr-3">{link1}</a>
    //                   <a href="#" className="btn more-btn">{link2}</a>
    //                 </div>
    //               </div>
    //             </div>
    //             <div className="col-lg-6">
    //               <div className="dotted mt-30 " data-aos-delay="500">
    //                 <img draggable="false" src={img} alt="" />
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </section>
    //   )}

    //   {HomeDemo2 && (
    //     <section className="hero-section app section-padding" id="home">
    //       <div className="hero-section-content">
    //         <div className="container">
    //           <div className="row align-items-center">
    //             <div className="col-12 col-lg-7 col-md-12">
    //               <div className="welcome-content">
    //                 <div className="promo-section">
    //                   <h3 className="special-head dark">{specialHead}</h3>
    //                 </div>
    //                 <h1>{title}</h1>
    //                 <p className="w-text">We have over 15 year exprience in business consultting arena. We have over 15 year exprience in business consultting arena and artficial intelligence.</p>
    //                 <div className="dream-btn-group">
    //                   <a href="#" className="btn more-btn mr-3">{link1}</a>
    //                   <a href="#" className="btn more-btn">{link2}</a>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </section>
    //   )}

    //   {HomeDemo3 && (
    //     <section className="hero-section de-3 section-padding" id="home">
    //       <div className="hero-section-content">
    //         <div className="container">
    //           <div className="row align-items-center">
    //             <div className="col-12 col-lg-5 col-md-12">
    //               <div className="welcome-content">
    //                 <div className="promo-section">
    //                   <h3 className="special-head dark">{specialHead}</h3>
    //                 </div>
    //                 <h1>{title}</h1>
    //                 <p className="w-text fadeInUp" data-wow-delay="0.3s">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eveniet dolorem blanditiis ad perferendis, labore delectus dolor sit amet, adipisicing elit. Eveniet.</p>
    //                 <div className="dream-btn-group fadeInUp" data-wow-delay="0.4s">
    //                   <a href="#" className="btn more-btn mr-3">{link1}</a>
    //                   <a href="#" className="btn more-btn">{link2}</a>
    //                 </div>
    //               </div>
    //             </div>
    //             <div className="col-lg-6">
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </section>
    //   )}

    //   {HomeDemo4 && (

    //     <section className={ClassSec} id="home">
    //       <div className="hero-section-content">
    //         <div className="container ">
    //           <div className="row align-items-center">
    //             <div className={ClassDiv}>
    //               <div className="welcome-content">
    //                 <div className="promo-section">
    //                   <h3 className="special-head dark">{specialHead}</h3>
    //                 </div>
    //                 <h1>{title}</h1>
    //                 <p className="w-text fadeInUp" data-wow-delay="0.3s">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eveniet dolorem blanditiis ad perferendis, labore delectus dolor sit amet, adipisicing elit. Eveniet.</p>
    //                 <div className="dream-btn-group fadeInUp" data-wow-delay="0.4s">
    //                   <a href="#" className="btn more-btn mr-3">{link1}</a>
    //                   <a href="#" className="btn more-btn">{link2}</a>
    //                 </div>
    //               </div>
    //             </div>
    //             {HomeDemo1Or3Or4Or5Or6 && (
    //               <div className="col-lg-6">
    //                 {HomeDemo1Or4Or5Or6 && (
    //                   <div className="illusto-2">
    //                     <img draggable="false" draggable="false" src={img} alt="" />
    //                   </div>
    //                 )}
    //               </div>
    //             )}
    //           </div>
    //         </div>
    //       </div>
    //     </section>

    //   )}

    //   {HomeDemo5 && (

    //     <section className="hero-section moving-2 section-padding" id="home">
    //       <div className="hero-section-content">
    //         <div className="container ">
    //           <div className="row align-items-center">
    //             <div className="col-12 col-lg-6 col-md-12">
    //               <div className="welcome-content">
    //                 <div className="promo-section">
    //                   <h3 className="special-head dark">{specialHead}</h3>
    //                 </div>
    //                 <h1>{title}</h1>
    //                 <p className="w-text fadeInUp" data-wow-delay="0.3s">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eveniet dolorem blanditiis ad perferendis, labore delectus dolor sit amet, adipisicing elit. Eveniet.</p>
    //                 <div className="dream-btn-group fadeInUp" data-wow-delay="0.4s">
    //                   <a href="#" className="btn more-btn mr-3">{link1}</a>
    //                   <a href="#" className="btn more-btn">{link2}</a>
    //                 </div>
    //               </div>
    //             </div>
    //             <div className="col-lg-6">
    //               <div className="illusto-1 mt-30 fadeInUp" data-wow-delay="0.5s">
    //                 <img draggable="false" draggable="false" src="img/core-img/hero-img.png" alt="" />
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </section>
    //   )}

    //   {HomeDemo6 && (

    //     <section className="hero-section moving section-padding" id="home">
    //       <div className="hero-section-content">
    //         <div className="container ">
    //           <div className="row align-items-center">
    //             <div className="col-12 col-lg-6 col-md-12">
    //               <div className="welcome-content">
    //                 <div className="promo-section">
    //                   <h3 className="special-head dark">{specialHead}</h3>
    //                 </div>
    //                 <h1>{title}</h1>
    //                 <p className="w-text fadeInUp" data-wow-delay="0.3s">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eveniet dolorem blanditiis ad perferendis, labore delectus dolor sit amet, adipisicing elit. Eveniet.</p>
    //                 <div className="dream-btn-group fadeInUp" data-wow-delay="0.4s">
    //                   <a href="#" className="btn more-btn mr-3">{link1}</a>
    //                   <a href="#" className="btn more-btn">{link2}</a>
    //                 </div>
    //               </div>
    //             </div>
    //             <div className="col-lg-6">
    //               <div className="illusto mt-30 fadeInUp" style={{}} data-wow-delay="0.5s">
    //                 <img draggable="false" draggable="false" src="img/core-img/about-1.png" alt="" />
    //               </div>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </section>

    //   )}
    // </>